import { useMemo } from 'react';
import { anyPass, prop } from 'ramda';

import {
  FactoryCellDataLink,
  FactoryCellInput,
  FactoryCellMultiDateConverter,
  FactoryCellNewestSelectMulti,
  FactoryCellSelect,
  FactoryCellSelectBinary,
  FactoryCellText,
} from 'shared/components/DataGrid';
import {
  isAdminRole,
  isReadOnlyRole,
  mapToIdDefaultNull,
  mapToIdsArray,
  mapToSelectOptions,
  mapValuesSeparatedWithComma,
} from 'shared/utils';
import {
  AlcoholType,
  AllocationPriority,
  Capability,
  CustomAssortment,
  LocationAutocompleteOptionsQueryVariables,
  LocationQuery,
  LocationQueryVariables,
  NationalAssortment,
  TobaccoType,
} from 'shared/types';
import {
  FilterSearchAutocomplete,
  FilterSearchAutocompleteMultiple,
  FilterSearchAutocompleteQuery,
  FilterSearchInput,
  FilterSearchSelectBinary,
} from 'shared/components/Search';
import { TooltipHeader } from 'shared/components/TooltipHeader';
import { inputTypes } from 'shared/components/Input/constants';
import { FilterSearchDatePicker } from 'shared/components/Search/Filter';

import { Row, UseColumnsArgs, UseColumnsResult } from '../types';
import { FactoryCellSelectAlcoholType } from '../components/FactoryCellSelectAlcoholType';
import { locationQuery } from '../operations';

const CellDataLink = FactoryCellDataLink();
const CellMultiDateConverter = FactoryCellMultiDateConverter(
  'MMM/dd/yyyy',
  [
    'locationCapabilitiesUpdatedAt',
    'locationCustomAssortmentsUpdatedAt',
    'locationAlcoholCapabilitiesUpdatedAt',
    'locationTobaccoCapabilitiesUpdatedAt',
  ],
  ['Capabilities', 'Custom Assortments', 'Alcohol', 'Tobacco Type'],
  'Location',
);
const isStopBuyingEnabled = prop('stopBuying');

const useColumns = ({
  alcoholTypes,
  capabilitiesOptions,
  customAssortmentsOptions,
  nationalAssortmentsOptions,
  allocationPriorityOptions,
  role,
  tobaccoTypesOptions,
  updateFilter,
  userOptions,
}: UseColumnsArgs): UseColumnsResult =>
  useMemo(() => {
    const isReadOnly = isReadOnlyRole(role);
    const isAdmin = isAdminRole(role);
    const isReadOnlyFunc = () => isReadOnly;
    const isNotAdminFunc = () => !isAdmin;

    const nameFilter = (
      <FilterSearchAutocompleteQuery<LocationQuery, LocationQueryVariables>
        filterFieldName="name"
        label="Location Name"
        mapDataToOptions={data => mapToSelectOptions(data.locations.locations)}
        mapUserInputToAutocompleteQueryFilerParams={value => ({
          name: value,
        })}
        onSubmitFilter={updateFilter}
        query={locationQuery}
      />
    );
    const idFilter = (
      <FilterSearchInput<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="extIds"
        label="Location ID"
        mapUserInputToFilerParams={value => ({
          extIds: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );
    const bevmoIdFilter = (
      <FilterSearchInput<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="bevmoLocIds"
        label="Bevmo ID"
        mapUserInputToFilerParams={value => ({
          bevmoLocIds: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );
    const isThirdPartyFilter = (
      <FilterSearchSelectBinary<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="isThirdParty"
        label="Is 3rd Party"
        onSubmitFilter={updateFilter}
      />
    );
    const nationalAssortmentFilter = (
      <FilterSearchAutocompleteMultiple<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="nationalAssortmentIds"
        label="National Assortment"
        onSubmitFilter={updateFilter}
        options={nationalAssortmentsOptions}
      />
    );
    const allocationPriorityFilter = (
      <FilterSearchAutocompleteMultiple<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="allocationPriorityIds"
        label="Allocation Priority"
        onSubmitFilter={updateFilter}
        options={allocationPriorityOptions}
      />
    );
    const customAssortmentFilter = (
      <FilterSearchAutocompleteMultiple<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="customAssortmentIds"
        label="Custom Assortment"
        onSubmitFilter={updateFilter}
        options={customAssortmentsOptions}
      />
    );
    const alcoholTypeFilter = (
      <FilterSearchAutocompleteMultiple<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="alcoholTypeIds"
        label={<TooltipHeader label="Alcohol Type" title="Selected is available" />}
        onSubmitFilter={updateFilter}
        options={mapToSelectOptions(alcoholTypes)}
      />
    );
    const restrictedTobaccoTypeFilter = (
      <FilterSearchAutocompleteMultiple<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="tobaccoTypeIds"
        label={<TooltipHeader label="Tobacco Type" title="Selected is available" />}
        onSubmitFilter={updateFilter}
        options={tobaccoTypesOptions}
      />
    );
    const locationCapabilitiesFilter = (
      <FilterSearchAutocompleteMultiple<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="capabilityIds"
        label="Location Capabilities"
        onSubmitFilter={updateFilter}
        options={capabilitiesOptions}
      />
    );
    const addressFilter = (
      <FilterSearchInput<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="address"
        label="Address"
        onSubmitFilter={updateFilter}
      />
    );

    const cityFilter = (
      <FilterSearchInput<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="city"
        label="City"
        onSubmitFilter={updateFilter}
      />
    );
    const stateFilter = (
      <FilterSearchInput<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="stateProvinceRegion"
        label="State"
        onSubmitFilter={updateFilter}
      />
    );
    const postalCodeFilter = (
      <FilterSearchInput<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="postalCode"
        label="Zip"
        onSubmitFilter={updateFilter}
      />
    );
    const typeFilter = (
      <FilterSearchInput<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="type"
        label="Type"
        onSubmitFilter={updateFilter}
      />
    );
    const openFromFilter = (
      <FilterSearchInput<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="openFrom"
        label="Open From"
        onSubmitFilter={updateFilter}
      />
    );
    const openToFilter = (
      <FilterSearchInput<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="openTo"
        label="Open To"
        onSubmitFilter={updateFilter}
      />
    );
    const metroRegionFilter = (
      <FilterSearchInput<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="metroRegion"
        label="Metro Region"
        onSubmitFilter={updateFilter}
      />
    );
    const districtIdFilter = (
      <FilterSearchInput<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="districtIds"
        label="District ID"
        mapUserInputToFilerParams={value => ({
          districtIds: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );
    const regionIdFilter = (
      <FilterSearchInput<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="regionIds"
        label="Region ID"
        mapUserInputToFilerParams={value => ({
          regionIds: mapValuesSeparatedWithComma(value),
        })}
        onSubmitFilter={updateFilter}
      />
    );
    const stopBuyingFilter = (
      <FilterSearchSelectBinary<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="stopBuying"
        label="Stop Buying"
        onSubmitFilter={updateFilter}
      />
    );
    const ageOutThresholdFilter = (
      <FilterSearchInput<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="ageOutThreshold"
        label={
          <TooltipHeader
            label="Age Out Threshold"
            title="Days since need by date when orders get automatically closed"
          />
        }
        onSubmitFilter={updateFilter}
      />
    );
    const updatedAtFilter = (
      <FilterSearchDatePicker<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="updatedAt"
        label="Updated At"
        onSubmitFilter={updateFilter}
      />
    );
    const updatedByFilter = (
      <FilterSearchAutocomplete<LocationAutocompleteOptionsQueryVariables>
        filterFieldName="updatedBy"
        label="Updated By"
        mapUserAutocompleteValueSelectToFilterParams={option => ({
          updatedBy: option!.value,
        })}
        onSubmitFilter={updateFilter}
        options={userOptions}
      />
    );

    return [
      {
        Header: () => nameFilter,
        accessor: 'name',
        Cell: CellDataLink,
        width: 240,
        sticky: 'left',
      },
      {
        Header: () => idFilter,
        accessor: 'extId',
      },
      {
        Header: () => bevmoIdFilter,
        accessor: 'bevmoLocId',
        Cell: FactoryCellText<Record<'bevmoLocId', string>>({
          dependencies: ['bevmoLocId'],
          pickValue: (row: Record<'bevmoLocId', string>) => row.bevmoLocId || '-',
        }),
      },
      {
        Header: () => stopBuyingFilter,
        accessor: 'stopBuying',
        Cell: FactoryCellSelectBinary({
          isDisabled: isNotAdminFunc,
          dependencies: ['stopBuying'],
        }),
      },
      {
        Header: () => allocationPriorityFilter,
        accessor: 'allocationPriority',
        Cell: FactoryCellSelect<Row, string, AllocationPriority>({
          options: allocationPriorityOptions,
          pickValue: mapToIdDefaultNull,
          isDisabled: anyPass([isReadOnlyFunc, isStopBuyingEnabled]),
          dependencies: ['stopBuying'],
        }),
      },
      {
        Header: () => nationalAssortmentFilter,
        accessor: 'nationalAssortment',
        Cell: FactoryCellSelect<Row, string, NationalAssortment>({
          options: nationalAssortmentsOptions,
          pickValue: mapToIdDefaultNull,
          isDisabled: isNotAdminFunc,
          dependencies: ['stopBuying'],
        }),
      },
      {
        Header: () => customAssortmentFilter,
        accessor: 'locationCustomAssortments',
        Cell: FactoryCellNewestSelectMulti<Row, string, CustomAssortment[]>({
          options: customAssortmentsOptions,
          pickValue: mapToIdsArray,
          isDisabled: anyPass([isReadOnlyFunc, isStopBuyingEnabled]),
          dependencies: ['stopBuying'],
        }),
      },
      {
        Header: () => alcoholTypeFilter,
        accessor: 'locationAlcoholCapabilities',
        Cell: FactoryCellSelectAlcoholType<Row>(
          alcoholTypes as AlcoholType[],
          anyPass([isReadOnlyFunc, isStopBuyingEnabled]),
          ['stopBuying'],
        ),
      },
      {
        Header: () => restrictedTobaccoTypeFilter,
        accessor: 'locationTobaccoCapabilities',
        Cell: FactoryCellNewestSelectMulti<Row, string, TobaccoType[]>({
          options: tobaccoTypesOptions,
          pickValue: mapToIdsArray,
          isDisabled: anyPass([isReadOnlyFunc, isStopBuyingEnabled]),
          dependencies: ['stopBuying'],
        }),
      },
      {
        Header: () => locationCapabilitiesFilter,
        accessor: 'locationCapabilities',
        Cell: FactoryCellNewestSelectMulti<Row, string, Capability[]>({
          options: capabilitiesOptions,
          pickValue: mapToIdsArray,
          isDisabled: anyPass([isReadOnlyFunc, isStopBuyingEnabled]),
          dependencies: ['stopBuying'],
        }),
      },
      {
        Header: () => isThirdPartyFilter,
        accessor: 'isThirdParty',
        Cell: FactoryCellSelectBinary({
          isDisabled: anyPass([isReadOnlyFunc, isStopBuyingEnabled]),
          dependencies: ['stopBuying'],
        }),
      },
      {
        Header: () => ageOutThresholdFilter,
        accessor: 'ageOutThreshold',
        Cell: FactoryCellInput({
          type: inputTypes.Number,
          isDisabled: anyPass([isReadOnlyFunc, isStopBuyingEnabled]),
          dependencies: ['stopBuying'],
        }),
        width: 150,
      },
      {
        Header: () => addressFilter,
        accessor: 'address',
        Cell: CellDataLink,
      },
      {
        Header: () => cityFilter,
        accessor: 'city',
        Cell: CellDataLink,
      },
      {
        Header: () => stateFilter,
        accessor: 'stateProvinceRegion',
        Cell: CellDataLink,
      },
      {
        Header: () => postalCodeFilter,
        accessor: 'postalCode',
        Cell: CellDataLink,
      },
      {
        Header: () => typeFilter,
        accessor: 'type',
      },
      {
        Header: () => openFromFilter,
        accessor: 'openFrom',
      },
      {
        Header: () => openToFilter,
        accessor: 'openTo',
      },
      {
        Header: () => metroRegionFilter,
        accessor: 'metroRegion',
      },
      {
        Header: () => districtIdFilter,
        accessor: 'districtId',
      },
      {
        Header: () => regionIdFilter,
        accessor: 'regionId',
      },
      {
        Header: () => updatedByFilter,
        id: 'locationExtraUpdatedBy',
        accessor: (row: Row) => {
          const user = userOptions
            ? userOptions.filter(u => u.value.includes(row.locationExtraUpdatedBy))
            : [];
          return row.locationExtraUpdatedBy && user.length
            ? user[0].label
            : row.locationExtraUpdatedBy || '-';
        },
      },
      {
        Header: () => updatedAtFilter,
        accessor: 'locationExtraUpdatedAt',
        Cell: CellMultiDateConverter,
        width: 170,
      },
    ];
  }, [
    alcoholTypes,
    capabilitiesOptions,
    customAssortmentsOptions,
    nationalAssortmentsOptions,
    allocationPriorityOptions,
    tobaccoTypesOptions,
    userOptions,
  ]);

export { useColumns };
